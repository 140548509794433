<template>
  <el-dialog
    v-if="showDialog"
    :modal="false"
    title="数据权限"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div
      v-loading="loading"
      class="orderTest-container"
      style="min-height: 500px"
    >
      <div class="title">
        <span style="margin-right: 20px">姓名:{{ info.user_name }}</span>
        <span style="margin-right: 20px">电话:{{ info.mobile }}</span>
        <span style="margin-right: 20px">部门:{{ info.depart_name }}</span>
        <span style="margin-right: 20px">职能:{{ info.roles }}</span>
      </div>
      <el-radio-group v-model="nav" style="margin-bottom: 10px">
        <el-radio-button label="1">仓库权限</el-radio-button>
        <el-radio-button label="2">员工权限</el-radio-button>
        <el-radio-button label="3">商品权限</el-radio-button>
        <el-radio-button label="4">客户权限</el-radio-button>
      </el-radio-group>
      <div class="centent">
        <depot
          v-if="list.length > 0"
          v-show="nav == '1'"
          :id="id"
          ref="depot"
          :data.sync="list"
          @refresh="fetchData"
          @close="close"
        ></depot>
        <div v-if="id != 0">
          <staff-permissions
            v-show="nav == '2'"
            :id="id"
            ref="staffPermissions"
            @close="close"
          ></staff-permissions>
          <goods-permissions
            v-show="nav == '3'"
            :id="id"
            ref="goodsPermissions"
            @close="close"
          ></goods-permissions>
          <client-permissions
            v-show="nav == '4'"
            :id="id"
            ref="clientPermissions"
            @close="close"
          ></client-permissions>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { depotDetail } from '@/api/staffPermissions'

  import Depot from './depot'
  import ClientPermissions from './clientPermissions'
  import GoodsPermissions from './goodsPermissions'
  import StaffPermissions from './staffPermissions'
  export default {
    name: 'Permissions',
    components: {
      Depot,
      StaffPermissions,
      ClientPermissions,
      GoodsPermissions,
    },
    data() {
      return {
        loading: false,
        // title
        info: {},
        showDialog: false,
        id: 0,
        nav: '1',
        list: [],
        msg: '11',
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          console.log('close')
          this.nav = '1'
          // this.$refs.staffPermissions.clearList()
          console.log(this.id)
        }
      },
      // id(val) {
      //   if (val) {
      //     console.log(this.id)
      //   }
      // },
    },
    created() {},
    mounted() {},
    methods: {
      // 子组件传入 title的信息
      // getInfo(val) {
      //   console.log(val)
      //   this.info = val.user_info
      //   this.list = val.list
      //   this.loading = false
      // },
      // startLoading() {
      //   this.loading = true
      // },
      async fetchData() {
        this.loading = true
        let { data } = await depotDetail({ user_id: this.id })
        console.log(data)
        this.info = data.user_info
        this.list = data.list

        // this.$refs.depot.list = JSON.parse(JSON.stringify(this.list))
        this.$refs.staffPermissions.fetchData()
        this.$refs.staffPermissions.getRightTableList()
        this.$refs.goodsPermissions.getBrandData()
        this.$refs.goodsPermissions.getClassData()
        this.$refs.goodsPermissions.getGoodsData()
        this.loading = false
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .title {
    margin-bottom: 10px;
    text-align: center;
  }
  // .centent {
  //   height: 600px;
  //   overflow: auto;
  // }
</style>
