var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-width": "200px",
            rules: _vm.rules,
            inline: true,
            size: "mini",
            "label-position": _vm.labelPosition,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "姓名", prop: "user_name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入姓名", autocomplete: "off" },
                model: {
                  value: _vm.form.user_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "user_name", $$v)
                  },
                  expression: "form.user_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手机号", prop: "mobile" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入手机号", autocomplete: "off" },
                model: {
                  value: _vm.form.mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mobile", $$v)
                  },
                  expression: "form.mobile",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "部门", prop: "depart_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "178px" },
                  attrs: {
                    filterable: "",
                    placeholder: "请选择部门",
                    autocomplete: "off",
                  },
                  model: {
                    value: _vm.form.depart_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "depart_id", $$v)
                    },
                    expression: "form.depart_id",
                  },
                },
                _vm._l(_vm.baseAdminsd, function (list, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: list.text, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "职能", prop: "user_type" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "178px" },
                  attrs: {
                    multiple: "",
                    filterable: "",
                    placeholder: "请选择职位",
                  },
                  model: {
                    value: _vm.form.user_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "user_type", $$v)
                    },
                    expression: "form.user_type",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "销售", value: "1" } }),
                  _c("el-option", { attrs: { label: "送货", value: "2" } }),
                  _c("el-option", { attrs: { label: "内部管理", value: "3" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "办公电话", prop: "office_mobile" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入办公电话" },
                model: {
                  value: _vm.form.office_mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "office_mobile", $$v)
                  },
                  expression: "form.office_mobile",
                },
              }),
            ],
            1
          ),
          _c("el-row", [_c("el-divider")], 1),
          _c(
            "el-form-item",
            { attrs: { label: "开通账号", prop: "account_status" } },
            [
              _c(
                "el-row",
                { staticStyle: { display: "flex", "flex-direction": "row" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "178px" },
                      attrs: { filterable: "", placeholder: "请选择账号" },
                      model: {
                        value: _vm.form.account_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "account_status", $$v)
                        },
                        expression: "form.account_status",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "正式账号", value: "2" },
                      }),
                      _c("el-option", {
                        attrs: { label: "暂不开通", value: "0" },
                      }),
                      _c("el-option", {
                        attrs: { label: "试用账号", value: "1" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "top",
                        width: "400",
                        trigger: "click",
                      },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "开通正式账号：占用您的端口数量，使用期限为协议到期时间"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 开通试用账号：临时试用体验账号，使用期限为7天，您可创建2个试用账号 "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 暂不开通账号：员工没有任何电脑后台和手机端系统使用权限，仅留存员工信息，可体现在单据中 "
                        ),
                      ]),
                      _c(
                        "div",
                        { attrs: { slot: "reference" }, slot: "reference" },
                        [
                          _c("i", {
                            staticClass: "el-icon-warning color",
                            staticStyle: {
                              "font-size": "25px",
                              color: "rgb(240, 175, 90)",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.account_status != "0" && _vm.form.account_status != ""
            ? _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色", prop: "roles" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "178px" },
                          attrs: {
                            multiple: "",
                            filterable: "",
                            placeholder: "请选择角色",
                          },
                          model: {
                            value: _vm.form.roles,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "roles", $$v)
                            },
                            expression: "form.roles",
                          },
                        },
                        _vm._l(_vm.rolelist, function (item, listindex) {
                          return _c("el-option", {
                            key: listindex,
                            attrs: {
                              label: item.role_name,
                              value: String(item.role_id),
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "登录账号", prop: "mobile" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "请输入登录账号" },
                        model: {
                          value: _vm.form.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mobile", $$v)
                          },
                          expression: "form.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "密码", prop: "pwd" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          placeholder: "请输入登录密码",
                        },
                        model: {
                          value: _vm.form.pwd,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "pwd", $$v)
                          },
                          expression: "form.pwd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "允许改销售价", prop: "is_edit_price" } },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.form.is_edit_price,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "is_edit_price", $$v)
                              },
                              expression: "form.is_edit_price",
                            },
                          }),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top",
                                width: "400",
                                trigger: "click",
                              },
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  "允许改销售价:默认为开，关闭后该员工不可修改订单销售价"
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-warning color",
                                    staticStyle: {
                                      "font-size": "25px",
                                      color: "rgb(240, 175, 90)",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "允许改调拨价", prop: "is_edit_price" } },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.form.is_edit_price_allot,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "is_edit_price_allot", $$v)
                              },
                              expression: "form.is_edit_price_allot",
                            },
                          }),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top",
                                width: "400",
                                trigger: "click",
                              },
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  "允许改调拨价:默认为关，开启后该员工可修改调拨单价格"
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-warning color",
                                    staticStyle: {
                                      "font-size": "25px",
                                      color: "rgb(240, 175, 90)",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "purple" }, [_vm._v("个人信息")]),
          _c("el-row", [_c("el-divider")], 1),
          _c(
            "el-form-item",
            { attrs: { label: "性别", prop: "sex" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "178px" },
                  attrs: { filterable: "", placeholder: "请选择性别" },
                  model: {
                    value: _vm.form.sex,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "sex", $$v)
                    },
                    expression: "form.sex",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "男", value: "1" } }),
                  _c("el-option", { attrs: { label: "女", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "职务", prop: "user_job" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.user_job,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "user_job", $$v)
                  },
                  expression: "form.user_job",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "住址", prop: "address" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "身份证号", prop: "idcard" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.idcard,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "idcard", $$v)
                  },
                  expression: "form.idcard",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "亲友姓名", prop: "friends_name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.friends_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "friends_name", $$v)
                  },
                  expression: "form.friends_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "亲友电话", prop: "friends_mobiel" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.friends_mobiel,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "friends_mobiel", $$v)
                  },
                  expression: "form.friends_mobiel",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }