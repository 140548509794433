<!--
 * @Author: 候怀烨
 * @Date: 2020-11-11 14:09:29
 * @LastEditTime: 2021-04-25 16:11:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: 123\sd-vue-admin\src\views\project\baseOrder\aboutSatff\satffDetail\index.vue
-->
<template>
  <!-- NAME[epic=基础] 员工资料 -->
  <div class="wrapper haerd">
    <div>
      <el-form
        ref="haerdform"
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
      >
        <el-form-item label="" prop="depart_id">
          <el-select
            v-model="formInline.depart_id"
            clearable
            filterable
            placeholder="选择部门"
            style="width: 130px"
          >
            <el-option
              v-for="(item, index) in baseAdminsd"
              :key="index"
              :label="item.text"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="role_id">
          <el-select
            v-model="formInline.role_id"
            clearable
            filterable
            placeholder="选择角色"
            style="width: 130px"
          >
            <el-option
              v-for="(item, index) in rolelist"
              :key="index"
              :label="item.role_name"
              :value="item.role_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="job_id">
          <el-select
            v-model="formInline.job_id"
            clearable
            filterable
            placeholder="选择职能"
            style="width: 130px"
          >
            <el-option
              v-for="(item, index) in position"
              :key="index"
              :label="item.text"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="is_wx">
          <el-select
            v-model="formInline.is_wx"
            clearable
            filterable
            placeholder="绑定微信"
            style="width: 130px"
          >
            <el-option
              v-for="(item, index) in wechat"
              :key="index"
              :label="item.text"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="keyword">
          <el-input
            v-model="formInline.keyword"
            placeholder="请输入姓名，电话"
            style="width: 180px"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="status">
          <el-select
            v-model="formInline.status"
            clearable
            filterable
            placeholder="状态"
            style="width: 130px"
          >
            <el-option
              v-for="(item, index) in status"
              :key="index"
              :label="item.text"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="info" @click="resetForm">重置</el-button>
        </el-form-item> -->
        <!-- <el-form-item>
          <el-button>批量导入</el-button>
        </el-form-item> -->
        <el-form-item>
          <add-employee
            ref="addedit"
            @getlist="getlist"
            @refresh="handlerData"
          ></add-employee>
        </el-form-item>
        <!-- <el-form-item>
          <el-button>导出</el-button>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="tableWrap">
      <div class="setFilter">
        <!-- <el-popover popper-class="custom-table-checkbox" trigger="hover">
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              v-for="(item, index) in columns"
              :key="index"
              :label="item.label"
            ></el-checkbox>
          </el-checkbox-group>
          <el-button slot="reference">
            <vab-remix-icon icon="settings-line" />
          </el-button>
        </el-popover> -->
      </div>
      <el-table ref="tableSort" v-loading="loading" stripe :data="list">
        <!-- 序号 -->
        <el-table-column align="center" type="index" width="50">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in columns"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <!-- 选择框 -->
        <el-table-column
          align="center"
          type="selection"
          width="50"
        ></el-table-column>
        <!-- 序号 -->
        <el-table-column
          v-for="(item, index) in finallyColumns"
          :key="index"
          :prop="item.prop"
          :align="item.align"
          :label="item.label"
          width=""
        >
          <!-- 排序 -->
          <template v-if="item.label === '排序'" #default="{ row }">
            <el-input
              v-model.trim.number="row.sort"
              @blur="blur_(row)"
            ></el-input>
            <!-- {{ row.sort }} -->
          </template>
          <template v-else-if="item.label == '角色'" #default="{ row }">
            <span v-if="row.is_admin == 1">管理员</span>
            <span v-else>{{ row.user_role }}</span>
          </template>
          <template v-else-if="item.label == '可改价'" #default="{ row }">
            <el-tooltip
              :content="row.setPrice === false ? '点击开启' : '点击关闭'"
              :enterable="false"
              placement="top"
            >
              <el-switch v-model="row.setPrice"></el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          align="center"
          label="操作"
          fixed="right"
          min-width="200px"
        >
          <template #default="{ $index, row }">
            <el-button
              v-show="row.is_close != 1"
              type="text"
              @click.native.prevent="editRow($index, row)"
            >
              编辑
            </el-button>
            <el-button
              v-show="row.is_admin != 1 && row.is_close != 1"
              type="text"
              @click.native.prevent="handlePermissions($index, row)"
            >
              数据权限
            </el-button>

            <el-button
              v-show="row.is_close == 0 && row.is_admin != 1"
              type="text"
              @click.native.prevent="saveRow(1, row)"
            >
              停用
            </el-button>
            <el-button
              v-show="row.is_close == 1"
              type="text"
              @click.native.prevent="saveRow(0, row)"
            >
              启用
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-row type="flex" class="row-bg" justify="end">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :page-size="10"
          :current-page.sync="formInline.pageNo"
          :total="Totalnumber"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </el-row>
    </div>

    <edit-row
      ref="editrow"
      @getlist="getlist"
      @refresh="handlerData"
    ></edit-row>
    <permissions ref="permissions" @refresh="handlerData"></permissions>
    <!-- <template></template> -->
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import AddEmployee from './components/Addemployee'
  import EditRow from './components/editRow'
  import Permissions from './components/permissions'
  import _ from 'lodash'
  export default {
    name: 'SatffDetail',
    components: {
      AddEmployee,
      EditRow,
      Permissions,
    },
    data() {
      return {
        formInline: {
          pageNo: 1,
          pageSize: 10,
          depart_id: '',
          role_id: '',
          is_wx: '',
          keyword: '',
          job_id: '',
          status: '',
        },
        handlershowgie: false,
        loading: false,
        // 表格
        Totalnumber: 0,
        pageNo: 1,
        pageSize: 10,
        checkList: [
          '排序',
          '姓名',
          '手机号',
          '登录账号',
          '角色',
          '部门',
          '绑定微信',
          '状态',
          '创建时间',
        ],
        columns: [
          {
            order: 1,
            label: '排序',
            align: 'center',
            width: '70px',
            prop: 'sort',
            sortable: false,
          },
          {
            order: 2,
            prop: 'user_name',
            align: 'center',
            label: '姓名',
            width: '100px',
          },
          {
            order: 3,
            prop: 'mobile',
            align: 'center',
            label: '手机号',
            width: '150px',
          },
          {
            order: 4,
            prop: 'login_user',
            align: 'center',
            label: '登录账号',
            width: '150px',
          },
          {
            order: 5,
            prop: 'user_role',
            align: 'center',
            label: '角色',
            width: '130px',
          },
          {
            order: 6,
            prop: 'depart_name',
            align: 'center',
            label: '部门',
            width: '120px',
          },
          {
            order: 7,
            prop: 'wx_id',
            align: 'center',
            label: '绑定微信',
            width: '130px',
          },
          {
            order: 8,
            prop: 'is_close_text',
            align: 'center',
            label: '状态',
            width: '80px',
          },
          {
            order: 9,
            prop: 'create_at',
            align: 'center',
            label: '创建时间',
            width: '150px',
          },
        ],
        list: [],
        url: {
          list: '/baseAdmin/user/index',
          baseAdmin: '/baseAdmin/depart/index',
          role: '/baseAdmin/common/role-list',
          zhuangtai: '/baseAdmin/user/status',
        },
        baseAdminsd: [],
        rolelist: [],
        position: [
          {
            text: '销售',
            id: '1',
          },
          {
            text: '送货',
            id: '2',
          },
          {
            text: '内部管理',
            id: '3',
          },
        ],
        wechat: [
          {
            text: '未绑定',
            id: '0',
          },
          {
            text: '已绑定',
            id: '1',
          },
        ],
        status: [
          {
            text: '正常',
            id: '0',
          },
          {
            text: '已停用',
            id: '1',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    created() {
      this.sort_()
    },
    mounted() {
      this.handlerData()
      this.handlerbumen()
      this.handlerjuse()
    },
    methods: {
      getlist() {
        setTimeout(() => {
          this.handlerData()
        }, 200)
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.formInline.pageSize = val
        this.formInline.pageNo = 1
        this.handlerData()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.formInline.pageNo = val
        this.handlerData()
      },
      //部门列表
      handlerbumen() {
        postAction(this.url.baseAdmin, { is_close: 0 })
          .then((res) => {
            console.log(res, 'bumen')
            const b = [...this.baseAdminsd, ...res.data.list]
            this.baseAdminsd = b
          })
          .catch((err) => {})
      },
      //角色
      handlerjuse() {
        postAction(this.url.role, {})
          .then((res) => {
            console.log(res, 'chenggong')
            const b = [...this.rolelist, ...res.data]
            this.rolelist = b
          })
          .catch((err) => {})
      },
      //用户；列表
      handlerData() {
        this.loading = true
        console.log(this.formInline, 'list')
        postAction(this.url.list, this.formInline)
          .then((res) => {
            // setTimeout(() => {
            //   this.loading = false
            // }, 2000)

            console.log(res, 'bumen')
            this.list = res.data
            this.list.forEach((item) => {
              item.sort = Number(item.sort)
            })
            this.Totalnumber = res.totalCount
            this.loading = false
          })
          .catch((err) => {})
      },
      //编辑用户信息
      editRow(index, row) {
        console.log(index, row)
        this.$refs.editrow.dialogVisible = true
        this.$refs.editrow.handlerData(row)
        this.handlerData()
      },
      // 排序输入框失去焦点 排序
      blur_(row) {
        // this.sort_()
        postAction('/baseAdmin/user/sort', {
          id: row.user_id,
          sort: row.sort,
        }).then((r) => {
          this.handlerData()
        })
      },
      // 排序
      sort_() {
        this.list = _.sortBy(this.list, (item) => item.sort)
      },
      //停用
      saveRow(index, row) {
        const data = {
          user_id: row.user_id,
          status: index,
        }
        console.log(index)
        if (index == 0) {
          console.log('启用吗')
          this.$confirm('确定启用此项吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              postAction(this.url.zhuangtai, data)
                .then((res) => {
                  console.log(res, '该状态')
                  this.$message.success('启用成功')
                  this.handlerData()
                })
                .catch((err) => {
                  console.log(err, '该状态2')
                })
            })
            .catch(() => {
              console.log('no')
            })
        } else if (index == 1) {
          console.log('停用吗')
          this.$confirm('确定停用此项吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              postAction(this.url.zhuangtai, data)
                .then((res) => {
                  console.log(res, '该状态')
                  this.$message.success('停用成功')

                  this.handlerData()
                })
                .catch((err) => {
                  console.log(err, '该状态2')
                })
            })
            .catch(() => {
              console.log('no')
            })
        }

        console.log(index, row)
      },
      onSubmit() {
        console.log('submit!')
        this.formInline.pageNo = 1
        this.handlerData()
      },
      handlePermissions(index, row) {
        console.log(row)
        this.$refs.permissions.id = row.user_id
        this.$refs.permissions.showDialog = true
      },
    },
  }
</script>
<style lang="scss">
  .haerd {
    padding: 20px 20px 0px 20px;
  }
  .tableWrap {
    position: relative;
  }
  .setFilter {
    position: absolute;
    top: 2px;
  }
</style>
