var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-alert", {
        attrs: {
          title:
            "请为员工分配仓库权限，内勤/库管建议勾选全部权限，业务/司机建议勾选调拨单、查询权限",
          type: "success",
          effect: "dark",
        },
      }),
      _c(
        "el-table",
        { attrs: { stripe: "", data: _vm.data, height: "500px" } },
        [
          _c(
            "el-table-column",
            {
              attrs: { align: "center", width: "50" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      row.depot_type == 1
                        ? _c("el-checkbox", {
                            attrs: { "true-label": 1, "false-label": 0 },
                            on: {
                              change: function ($event) {
                                return _vm.selectChangeA($event, row)
                              },
                            },
                            model: {
                              value: row.is_default,
                              callback: function ($$v) {
                                _vm.$set(row, "is_default", $$v)
                              },
                              expression: "row.is_default",
                            },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [_c("template", { slot: "header" }, [_vm._v("默认")])],
            2
          ),
          _vm._l(_vm.finallyColumns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: "",
                align: "center",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label == "仓库"
                          ? _c("div", [
                              _c("span", [_vm._v(_vm._s(row[item.prop]))]),
                            ])
                          : item.label == "全部权限"
                          ? _c(
                              "div",
                              [
                                _c("el-checkbox", {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  on: {
                                    change: function ($event) {
                                      return _vm.allPermissionsSelect(
                                        $event,
                                        row
                                      )
                                    },
                                  },
                                  model: {
                                    value: row.auth_all,
                                    callback: function ($$v) {
                                      _vm.$set(row, "auth_all", $$v)
                                    },
                                    expression: "row.auth_all",
                                  },
                                }),
                              ],
                              1
                            )
                          : item.label == "入库单"
                          ? _c(
                              "div",
                              [
                                _c("el-checkbox", {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  on: {
                                    change: function ($event) {
                                      return _vm.selectRow($event, row, 1)
                                    },
                                  },
                                  model: {
                                    value: row.auth_1,
                                    callback: function ($$v) {
                                      _vm.$set(row, "auth_1", $$v)
                                    },
                                    expression: "row.auth_1",
                                  },
                                }),
                              ],
                              1
                            )
                          : item.label == "出库单"
                          ? _c(
                              "div",
                              [
                                _c("el-checkbox", {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  on: {
                                    change: function ($event) {
                                      return _vm.selectRow($event, row, 2)
                                    },
                                  },
                                  model: {
                                    value: row.auth_2,
                                    callback: function ($$v) {
                                      _vm.$set(row, "auth_2", $$v)
                                    },
                                    expression: "row.auth_2",
                                  },
                                }),
                              ],
                              1
                            )
                          : item.label == "调拨"
                          ? _c(
                              "div",
                              [
                                _c("el-checkbox", {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  on: {
                                    change: function ($event) {
                                      return _vm.selectRow($event, row, 3)
                                    },
                                  },
                                  model: {
                                    value: row.auth_3,
                                    callback: function ($$v) {
                                      _vm.$set(row, "auth_3", $$v)
                                    },
                                    expression: "row.auth_3",
                                  },
                                }),
                              ],
                              1
                            )
                          : item.label == "盘点"
                          ? _c(
                              "div",
                              [
                                _c("el-checkbox", {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  on: {
                                    change: function ($event) {
                                      return _vm.selectRow($event, row, 4)
                                    },
                                  },
                                  model: {
                                    value: row.auth_4,
                                    callback: function ($$v) {
                                      _vm.$set(row, "auth_4", $$v)
                                    },
                                    expression: "row.auth_4",
                                  },
                                }),
                              ],
                              1
                            )
                          : item.label == "查询"
                          ? _c(
                              "div",
                              [
                                _c("el-checkbox", {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  on: {
                                    change: function ($event) {
                                      return _vm.selectRow($event, row, 5)
                                    },
                                  },
                                  model: {
                                    value: row.auth_5,
                                    callback: function ($$v) {
                                      _vm.$set(row, "auth_5", $$v)
                                    },
                                    expression: "row.auth_5",
                                  },
                                }),
                              ],
                              1
                            )
                          : item.label == "审核"
                          ? _c(
                              "div",
                              [
                                _c("el-checkbox", {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  on: {
                                    change: function ($event) {
                                      return _vm.selectRow($event, row, 6)
                                    },
                                  },
                                  model: {
                                    value: row.auth_6,
                                    callback: function ($$v) {
                                      _vm.$set(row, "auth_6", $$v)
                                    },
                                    expression: "row.auth_6",
                                  },
                                }),
                              ],
                              1
                            )
                          : item.label == "作废"
                          ? _c(
                              "div",
                              [
                                _c("el-checkbox", {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  on: {
                                    change: function ($event) {
                                      return _vm.selectRow($event, row, 7)
                                    },
                                  },
                                  model: {
                                    value: row.auth_7,
                                    callback: function ($$v) {
                                      _vm.$set(row, "auth_7", $$v)
                                    },
                                    expression: "row.auth_7",
                                  },
                                }),
                              ],
                              1
                            )
                          : item.label == "冲改"
                          ? _c(
                              "div",
                              [
                                _c("el-checkbox", {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  on: {
                                    change: function ($event) {
                                      return _vm.selectRow($event, row, 8)
                                    },
                                  },
                                  model: {
                                    value: row.auth_8,
                                    callback: function ($$v) {
                                      _vm.$set(row, "auth_8", $$v)
                                    },
                                    expression: "row.auth_8",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("div"),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _c("div", { staticStyle: { margin: "10px 0" } }, [
        _vm._v(" 选中的仓库，将作为下单、调拨、退库等操作的默认仓库 "),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
            [_vm._v("保存仓库权限")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }