import request from '@/utils/request'

// 员工-仓库关联设置页面
export function depotDetail(data) {
  return request({
    url: '/baseAdmin/user-rel/depot-rel-list',
    method: 'post',
    data,
  })
}

// 仓库关联设置提交
export function depotSubmit(data) {
  return request({
    url: '/baseAdmin/user-rel/depot-rel',
    method: 'post',
    data,
  })
}

// 员工员工关联列表
export function getUserData(data) {
  return request({
    url: '/baseAdmin/user-rel/user-rel-list',
    method: 'post',
    data,
  })
}

// 部门关联列表
export function getTree(data) {
  return request({
    url: '/baseAdmin/user-rel/depart-rel-list',
    method: 'post',
    data,
  })
}

// 员工员工关联列表   右边表格默认list
export function rightTableList(data) {
  return request({
    url: '/baseAdmin/user-rel/user-user-list',
    method: 'post',
    data,
  })
}

// 员工权限提交
export function staffSubmit(data) {
  return request({
    url: '/baseAdmin/user-rel/rel',
    method: 'post',
    data,
  })
}

// 品类列表
export function classList(data) {
  return request({
    url: '/baseAdmin/user-rel/goods-class-rel-list',
    method: 'post',
    data,
  })
}

// 品牌列表
export function brandList(data) {
  return request({
    url: '/baseAdmin/user-rel/brand-rel-list',
    method: 'post',
    data,
  })
}

// 员工产品列表
export function goodsList(data) {
  return request({
    url: '/baseAdmin/user-rel/goods-rel-list',
    method: 'post',
    data,
  })
}
// 部门资料员工列表
export function goodsListBMQX(data) {
  return request({
    url: '/baseAdmin/depart/goods-list',
    method: 'post',
    data,
  })
}

// 商品提交
export function goodsSubmit(data) {
  return request({
    url: '/baseAdmin/user-rel/goods-rel',
    method: 'post',
    data,
  })
}

// 销售区域列表
export function saleAreaList(data) {
  return request({
    url: '/baseAdmin/user-rel/area-rel-list',
    method: 'post',
    data,
  })
}

// 员工渠道列表
export function channelList(data) {
  return request({
    url: '/baseAdmin/user-rel/channel-rel-list',
    method: 'post',
    data,
  })
}

// 员工-客户等级列表
export function levelList(data) {
  return request({
    url: '/baseAdmin/user-rel/customer-class-rel-list',
    method: 'post',
    data,
  })
}

// 客户 提交
export function clientSubmit(data) {
  return request({
    url: '/baseAdmin/user-rel/customer-rel',
    method: 'post',
    data,
  })
}
