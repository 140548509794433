var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "编辑",
            visible: _vm.dialogVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "200px",
                    rules: _vm.rules,
                    inline: true,
                    size: "mini",
                    "label-position": _vm.labelPosition,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名", prop: "user_name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入姓名",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.form.user_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "user_name", $$v)
                          },
                          expression: "form.user_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号", prop: "mobile" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入手机号",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.form.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mobile", $$v)
                          },
                          expression: "form.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "部门", prop: "depart_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "178px" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择部门",
                            autocomplete: "off",
                          },
                          model: {
                            value: _vm.form.depart_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "depart_id", $$v)
                            },
                            expression: "form.depart_id",
                          },
                        },
                        _vm._l(_vm.baseAdminsd, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.text, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "职能", prop: "user_type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "178px" },
                          attrs: {
                            multiple: "",
                            filterable: "",
                            autocomplete: "off",
                            placeholder: "请选择职位",
                          },
                          model: {
                            value: _vm.form.user_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "user_type", $$v)
                            },
                            expression: "form.user_type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "销售", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "送货", value: "2" },
                          }),
                          _c("el-option", {
                            attrs: { label: "内部管理", value: "3" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "办公电话", prop: "office_mobile" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入办公电话" },
                        model: {
                          value: _vm.form.office_mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "office_mobile", $$v)
                          },
                          expression: "form.office_mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-row", [_c("el-divider")], 1),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开通账号", prop: "account_status" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "178px" },
                          attrs: {
                            disabled: _vm.form.is_admin == 1,
                            filterable: "",
                            autocomplete: "off",
                            placeholder: "请选择账号",
                          },
                          model: {
                            value: _vm.form.account_status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "account_status", $$v)
                            },
                            expression: "form.account_status",
                          },
                        },
                        _vm._l(_vm.zhagnhao, function (list, inde) {
                          return _c("el-option", {
                            key: inde,
                            attrs: { label: list.text, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.account_status != "0" &&
                  _vm.form.account_status != ""
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "角色", prop: "roles" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "178px" },
                                  attrs: {
                                    disabled: _vm.form.is_admin == 1,
                                    multiple: "",
                                    filterable: "",
                                    placeholder: "请选择角色",
                                  },
                                  model: {
                                    value: _vm.form.roles,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "roles", $$v)
                                    },
                                    expression: "form.roles",
                                  },
                                },
                                _vm._l(
                                  _vm.rolelist,
                                  function (item, listindex) {
                                    return _c("el-option", {
                                      key: listindex,
                                      attrs: {
                                        label: item.role_name,
                                        value: item.role_id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "登录账号", prop: "login_user" },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入登录账号" },
                                model: {
                                  value: _vm.form.login_user,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "login_user", $$v)
                                  },
                                  expression: "form.login_user",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "密码", prop: "pwd" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "password",
                                  placeholder: "请输入登录密码",
                                },
                                model: {
                                  value: _vm.form.pwd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "pwd", $$v)
                                  },
                                  expression: "form.pwd",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "允许改销售价",
                                prop: "is_edit_price",
                              },
                            },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.form.is_edit_price,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "is_edit_price", $$v)
                                  },
                                  expression: "form.is_edit_price",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "允许改调拨价",
                                prop: "is_edit_price",
                              },
                            },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.form.is_edit_price_allot,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "is_edit_price_allot",
                                      $$v
                                    )
                                  },
                                  expression: "form.is_edit_price_allot",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "purple" }, [_vm._v("个人信息")]),
                  _c("el-row", [_c("el-divider")], 1),
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别", prop: "sex" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "178px" },
                          attrs: { placeholder: "请选择性别", filterable: "" },
                          model: {
                            value: _vm.form.sex,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "sex", $$v)
                            },
                            expression: "form.sex",
                          },
                        },
                        _vm._l(_vm.sex, function (item, und) {
                          return _c("el-option", {
                            key: und,
                            attrs: { label: item.text, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "职务", prop: "user_job" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.user_job,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "user_job", $$v)
                          },
                          expression: "form.user_job",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "住址", prop: "address" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "address", $$v)
                          },
                          expression: "form.address",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份证号", prop: "idcard" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.idcard,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "idcard", $$v)
                          },
                          expression: "form.idcard",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "亲友姓名", prop: "friends_name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.friends_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "friends_name", $$v)
                          },
                          expression: "form.friends_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "亲友电话", prop: "friends_mobiel" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.friends_mobiel,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "friends_mobiel", $$v)
                          },
                          expression: "form.friends_mobiel",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }