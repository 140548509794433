<template>
  <div class="orderTest-container">
    <el-alert
      title="可以为经理或业务添加指定关联人，关联后经理在手机端可查看关联人业绩，业务做访销单可选送货司机；内勤默认可查看全部员工订单，勾选关联员工后，仅可查看指定人员的订单"
      type="success"
      effect="dark"
    ></el-alert>
    <div v-loading="loading" class="wrapper">
      <div class="content">
        <div class="tree">
          <el-tree
            :data="treeData"
            :props="defaultProps"
            node-key="id"
            :default-expanded-keys="[1]"
            highlight-current
            style="height: 500px; overflow: auto"
            @node-click="handleNodeClick"
          ></el-tree>
        </div>
        <div class="leftTable">
          <el-table
            v-loading="lTableLoading"
            stripe
            height="500px"
            :data="lTableData"
            border
            @selection-change="lTableClick"
          >
            <!-- 选择框 -->
            <el-table-column
              align="center"
              type="selection"
              width="50"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="user_name"
              label="业务员姓名"
              width="auto"
            ></el-table-column>
          </el-table>
          <el-button
            style="float: right; margin-top: 5px"
            type="primary"
            @click="add"
          >
            添加
          </el-button>
        </div>
        <div class="middleImg">
          <i
            class="el-icon-caret-right"
            style="cursor: pointer"
            @click="add"
          ></i>
        </div>
        <div class="rightTable">
          <el-table
            v-loading="rTableLoading"
            stripe
            :data="rTableData"
            border
            height="500px"
            @selection-change="rTableClick"
          >
            <el-table-column
              align="center"
              prop="user_name"
              label="姓名"
              width="110"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="roles"
              label="角色"
              width="auto"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="mobile"
              label="电话"
              width="110"
            ></el-table-column>
            <el-table-column align="center" prop="" label="操作" width="60">
              <template #default="{ $index }">
                <el-button type="text" @click="handleDelete($index)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="handleSave">保存员工权限</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </div>
</template>
<script>
  import {
    getTree,
    getUserData,
    rightTableList,
    staffSubmit,
  } from '@/api/staffPermissions'
  export default {
    name: 'StaffPermissions',
    components: {},
    props: {
      id: {
        type: Number,
        default: () => 0,
      },
    },
    data() {
      return {
        loading: false,
        lTableLoading: false,
        rTableLoading: false,
        tableRowSelect: [],
        treeData: [],
        lTableData: [],
        rTableData: [],
        defaultProps: {
          children: 'children',
          label: 'text',
        },
      }
    },
    computed: {},
    watch: {},
    created() {
      console.log('created')
    },
    mounted() {
      console.log('mounted')
      // this.fetchData()
      // this.getRightTableList()
    },
    methods: {
      // 树 数据获取
      async fetchData() {
        this.loading = true
        let { data, msg, code } = await getTree({ user_id: this.id })
        console.log(data)
        if (code == 200) {
          this.treeData = data.tree
          // this.rTableData = data.list
        } else {
          this.$message.error(msg)
        }
        this.loading = false
      },
      // 中间表格数据获取
      async getlTable(id) {
        this.lTableLoading = true
        let { data, msg, code } = await getUserData({
          user_id: this.id,
          depart_id: id,
        })
        console.log(data, code)
        if (code == 200) {
          this.lTableData = data
        } else {
          this.$message.error(msg)
        }
        this.lTableLoading = false
      },
      // 右边表格获取
      async getRightTableList() {
        this.rTableLoading = true
        let { data } = await rightTableList({ user_id: this.id })
        console.log(data)
        this.rTableData = data
        this.rTableLoading = false
      },
      // 树形节点点击事件
      handleNodeClick(val) {
        this.getlTable(val.id)
      },
      // 左右表格 row 点击事件
      lTableClick(val) {
        console.log(val)
        this.tableRowSelect = val
      },
      rTableClick(val) {
        console.log(val)
      },
      add() {
        let select = this.tableRowSelect
        console.log(select)
        if (select.length == 0) {
          this.$message.error('请选择员工')
          return false
        }
        select.forEach((item) => {
          // 先判断存不存在相同
          let haveed = this.rTableData.filter(
            (rTdItem) => rTdItem.user_id == item.user_id
          )
          console.log(haveed)
          if (haveed.length !== 0) {
            try {
              haveed.forEach((item1) => {
                // this.$message.error('业务员 ' + item1.user_name + ' 已存在')
                // throw new Error('存在相同业务员')
                return false
              })
            } catch (e) {
              if (e.message == '存在相同业务员') {
                throw e
              }
            }
          } else {
            this.rTableData.push(item)
          }
        })
        // this.$message.success('添加成功')
      },
      // 删除
      handleDelete(index) {
        console.log('删除', index)
        this.rTableData.splice(index, 1)
      },
      clearList() {
        this.lTableData = []
      },
      close() {
        this.$emit('close')
      },
      handleSave() {
        let data = this.rTableData
        let userArr = []
        data.forEach((item) => {
          userArr.push(item.user_id)
        })
        userArr = JSON.stringify(userArr) == '[]' ? '' : JSON.stringify(userArr)
        console.log(userArr)
        const end = {
          user_id: this.id,
          type: 2,
          rel_data: userArr,
        }
        staffSubmit(end).then((res) => {
          // this.$emit('refresh')
          this.$message.success(res.msg)
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    min-height: 500px;
  }
  .content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .tree {
    width: 25%;
  }
  .leftTable {
    box-sizing: border-box;
    width: 25%;
    padding: 0 10px;
  }
  .middleImg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 5%;
    text-align: center;
    i {
      width: 100%;
      font-size: 50px;
      color: #000;
    }
  }
  .rightTable {
    width: 45%;
    padding: 0 10px;
  }
</style>
