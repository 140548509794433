<template>
  <div class="orderTest-container">
    <el-alert
      title="当启用客户按区域共享时，业务员负责此处分配的区域、渠道、等级客户"
      type="success"
      effect="dark"
    ></el-alert>
    <div style="display: flex; justify-content: space-around">
      <div style="width: 360px">
        <el-table
          stripe
          :data="saleAreaSelect"
          border
          height="500px"
          row-key="id"
          default-expand-all
          :indent="35"
          :tree-props="{
            children: 'children',
            hasChildren: 'hasChildren',
          }"
        >
          >
          <!-- 选择框 -->
          <el-table-column type="" align="center" width="50">
            <template slot="header">
              <el-checkbox @change="handleChangeAreaAll"></el-checkbox>
            </template>
            <template #default="{ row }">
              <el-checkbox
                v-model="row.is_rel"
                :true-label="1"
                :false-label="0"
                @change="handleSaleAreaSelect($event, row)"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            prop="text"
            label="销售区域"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <div style="width: 300px">
        <el-table stripe :data="channelSelect" border height="500px">
          <!-- 选择框 -->
          <el-table-column align="center" width="50">
            <template slot="header">
              <el-checkbox @change="handleChangeChannelAll"></el-checkbox>
            </template>
            <template #default="{ row }">
              <el-checkbox
                v-model="row.is_rel"
                :true-label="1"
                :false-label="0"
                @change="handleChannel($event, row)"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            prop="channel_name"
            label="渠道"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <div style="width: 240px">
        <el-table stripe :data="levelSelect" border height="500px">
          <!-- 选择框 -->
          <el-table-column align="center" width="50">
            <template slot="header">
              <el-checkbox @change="handleChangeLevelAll"></el-checkbox>
            </template>
            <template #default="{ row }">
              <el-checkbox
                v-model="row.is_rel"
                :true-label="1"
                :false-label="0"
                @change="handleLevel($event, row)"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            prop="class_name"
            label="客户等级"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
    </div>
    <div style="margin: 5px 8px">
      <el-checkbox
        v-model="true1"
        label="给予全部客户权限"
        @change="hanldeCheckAll($event, 1)"
      ></el-checkbox>
      <el-checkbox
        v-model="true2"
        label="无客户权限"
        @change="hanldeCheckAll($event, 0)"
      ></el-checkbox>
      <!-- <el-button @click="hanldeCheckAll(1)">勾选全部权限</el-button>
      <el-button @click="hanldeCheckAll(0)">清空全部权限</el-button> -->
    </div>
    <div slot="footer" class="dialog-footer" style="text-align: right">
      <el-button type="primary" @click="handleSave">保存客户权限</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </div>
</template>
<script>
  import {
    saleAreaList,
    channelList,
    levelList,
    clientSubmit,
  } from '@/api/staffPermissions'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'ClientPermissions',
    components: {},
    // eslint-disable-next-line vue/require-prop-types
    props: ['id'],
    data() {
      return {
        true1: false,
        true2: false,
        saleAreaSelect: [],
        channelSelect: [],
        levelSelect: [],
      }
    },
    computed: {},
    watch: {},
    created() {
      this.initList()
    },
    mounted() {},
    methods: {
      initList() {
        this.getSaleArea()
        this.getChannel()
        this.getlevel()
        postAction('/baseAdmin/user-rel/customer-list', {
          user_id: this.id,
        }).then((res) => {
          console.log('new   asd', res)
          if (res.data.all_clear == 1) {
            this.true1 = true
            this.selectAll()
          } else if (res.data.all_clear == 2) {
            this.true2 = true
          }
        })
      },
      async getSaleArea() {
        let { data } = await saleAreaList({ user_id: this.id })
        this.saleAreaSelect = data.tree
      },
      async getChannel() {
        let { data } = await channelList({ user_id: this.id })
        this.channelSelect = data
      },
      async getlevel() {
        let { data } = await levelList({ user_id: this.id })
        this.levelSelect = data
      },
      close() {
        this.$emit('close')
      },
      selectAll() {
        this.hanldeCheckAll(true, 1)
      },
      handleSave() {
        let area = [],
          channel = [],
          level = []
        this.channelSelect.forEach((item) => {
          if (item.is_rel == 1) {
            channel.push(item.channel_id)
          }
        })
        this.levelSelect.forEach((item) => {
          if (item.is_rel == 1) {
            level.push(item.class_id)
          }
        })
        if (this.saleAreaSelect) {
          this.saleAreaSelect.forEach((item) => {
            // if (item.children) {
            //   item.children.forEach((item2) => {
            //     if (item2.is_rel == 1) {
            //       area.push(item2.id)
            //     }
            //   })
            // }
            if (item.is_rel == 1) {
              area.push(item.id)
            }
            if (item.children) {
              item.children.forEach((item2) => {
                if (item2.is_rel == 1) {
                  area.push(item2.id)
                }
                if (item2.children) {
                  item2.children.forEach((item3) => {
                    if (item3.is_rel == 1) {
                      area.push(item3.id)
                    }
                    if (item3.children) {
                      item3.children.forEach((item4) => {
                        if (item4.is_rel == 1) {
                          area.push(item4.id)
                        }
                        if (item4.children) {
                          item4.children.forEach((i) => {
                            if (i.is_rel == 1) {
                              area.push(i.id)
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        }

        area = JSON.stringify(area) == '[]' ? '' : JSON.stringify(area)
        channel = JSON.stringify(channel) == '[]' ? '' : JSON.stringify(channel)
        level = JSON.stringify(level) == '[]' ? '' : JSON.stringify(level)
        const end = {
          user_id: this.id,
          area_data: area == '' ? '[]' : area,
          channel_data: channel,
          customer_class_data: level,
          all_clear: this.true1 ? 1 : this.true2 ? 2 : null,
        }
        clientSubmit(end).then((res) => {
          this.$message.success(res.msg)
        })
      },

      handleSaleAreaSelect(val, row) {
        console.log('销售区域', row)
        this.true1 = false
        this.true2 = false
        if (row.is_rel && row.children) {
          row.children.forEach((c) => {
            c.is_rel = 1
            if (c.children) {
              c.children.forEach((z) => {
                z.is_rel = 1
              })
            }
          })
        }
      },
      handleChannel(val, row) {
        console.log('渠道', row)
        this.true1 = false
        this.true2 = false
      },
      handleLevel(val, row) {
        console.log('客户等级', row)
        this.true1 = false
        this.true2 = false
      },
      handleChangeAreaAll(val) {
        this.true1 = false
        this.true2 = false
        this.saleAreaSelect.forEach((i) => {
          i.is_rel = val ? 1 : 0
          if (i.children) {
            i.children.forEach((z) => {
              z.is_rel = val ? 1 : 0
              if (z.children) {
                z.children.forEach((a) => {
                  a.is_rel = val ? 1 : 0
                  if (a.children) {
                    a.children.forEach((aa) => {
                      aa.is_rel = val ? 1 : 0
                    })
                  }
                })
              }
            })
          }
        })
      },
      handleChangeChannelAll(val) {
        this.true1 = false
        this.true2 = false
        this.channelSelect.forEach((i) => {
          i.is_rel = val
        })
      },
      handleChangeLevelAll(val) {
        this.true1 = false
        this.true2 = false
        this.levelSelect.forEach((i) => {
          i.is_rel = val
        })
      },
      hanldeCheckAll(val, type) {
        if (type == 1 && val) {
          this.true2 = false
        } else if (type == 0 && val) {
          this.true1 = false
        }
        if (val) {
          this.channelSelect.forEach((i) => {
            i.is_rel = type
          })
          this.levelSelect.forEach((i) => {
            i.is_rel = type
          })
          this.saleAreaSelect.forEach((i) => {
            i.is_rel = type
            if (i.children) {
              i.children.forEach((z) => {
                z.is_rel = type
                if (z.children) {
                  z.children.forEach((a) => {
                    a.is_rel = type
                    if (a.children) {
                      a.children.forEach((aa) => {
                        aa.is_rel = type
                      })
                    }
                  })
                }
              })
            }
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
