var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c("el-alert", {
        attrs: {
          title:
            "当启用客户按区域共享时，业务员负责此处分配的区域、渠道、等级客户",
          type: "success",
          effect: "dark",
        },
      }),
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "space-around" } },
        [
          _c(
            "div",
            { staticStyle: { width: "360px" } },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    stripe: "",
                    data: _vm.saleAreaSelect,
                    border: "",
                    height: "500px",
                    "row-key": "id",
                    "default-expand-all": "",
                    indent: 35,
                    "tree-props": {
                      children: "children",
                      hasChildren: "hasChildren",
                    },
                  },
                },
                [
                  _vm._v(" > "),
                  _c(
                    "el-table-column",
                    {
                      attrs: { type: "", align: "center", width: "50" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("el-checkbox", {
                                attrs: { "true-label": 1, "false-label": 0 },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleSaleAreaSelect($event, row)
                                  },
                                },
                                model: {
                                  value: row.is_rel,
                                  callback: function ($$v) {
                                    _vm.$set(row, "is_rel", $$v)
                                  },
                                  expression: "row.is_rel",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c("el-checkbox", {
                            on: { change: _vm.handleChangeAreaAll },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: { prop: "text", label: "销售区域", align: "center" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { width: "300px" } },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    stripe: "",
                    data: _vm.channelSelect,
                    border: "",
                    height: "500px",
                  },
                },
                [
                  _c(
                    "el-table-column",
                    {
                      attrs: { align: "center", width: "50" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("el-checkbox", {
                                attrs: { "true-label": 1, "false-label": 0 },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleChannel($event, row)
                                  },
                                },
                                model: {
                                  value: row.is_rel,
                                  callback: function ($$v) {
                                    _vm.$set(row, "is_rel", $$v)
                                  },
                                  expression: "row.is_rel",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c("el-checkbox", {
                            on: { change: _vm.handleChangeChannelAll },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: {
                      prop: "channel_name",
                      label: "渠道",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { width: "240px" } },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    stripe: "",
                    data: _vm.levelSelect,
                    border: "",
                    height: "500px",
                  },
                },
                [
                  _c(
                    "el-table-column",
                    {
                      attrs: { align: "center", width: "50" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("el-checkbox", {
                                attrs: { "true-label": 1, "false-label": 0 },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleLevel($event, row)
                                  },
                                },
                                model: {
                                  value: row.is_rel,
                                  callback: function ($$v) {
                                    _vm.$set(row, "is_rel", $$v)
                                  },
                                  expression: "row.is_rel",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c("el-checkbox", {
                            on: { change: _vm.handleChangeLevelAll },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: {
                      prop: "class_name",
                      label: "客户等级",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticStyle: { margin: "5px 8px" } },
        [
          _c("el-checkbox", {
            attrs: { label: "给予全部客户权限" },
            on: {
              change: function ($event) {
                return _vm.hanldeCheckAll($event, 1)
              },
            },
            model: {
              value: _vm.true1,
              callback: function ($$v) {
                _vm.true1 = $$v
              },
              expression: "true1",
            },
          }),
          _c("el-checkbox", {
            attrs: { label: "无客户权限" },
            on: {
              change: function ($event) {
                return _vm.hanldeCheckAll($event, 0)
              },
            },
            model: {
              value: _vm.true2,
              callback: function ($$v) {
                _vm.true2 = $$v
              },
              expression: "true2",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
            [_vm._v("保存客户权限")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }