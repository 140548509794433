<!--
 * @Author: 候怀烨
 * @Date: 2020-11-11 15:38:50
 * @LastEditTime: 2021-05-14 14:32:47
 * @LastEditors: Please set LastEditors
 * @Description: 添加员工
 * @FilePath: \sd-vue-admin\src\views\project\baseOrder\aboutSatff\satffDetail\components\Addemployee.vue
-->
<template>
  <div class="content">
    <el-button type="primary" @click="dialogVisible = true">添加员工</el-button>

    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="添加员工"
      :visible.sync="dialogVisible"
      width="60%"
      center
    >
      <div class="dialong">
        <in-formation
          ref="zhanghao"
          @show="show"
          @closeShow="close"
        ></in-formation>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="warning" @click="addsubmit">保存并新增</el-button>
        <el-button type="primary" @click="submit">保存</el-button>
        <el-button @click="cancel">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import InFormation from './information'
  export default {
    components: {
      InFormation,
    },
    data() {
      return {
        dialogVisible: false,
        activeName: '',
      }
    },
    watch: {
      dialogVisible(val) {
        if (!val) {
          this.$refs.zhanghao.form.mobile = ''
          this.$emit('getlist')
        }
      },
    },
    methods: {
      //获取需要修改的用户数据
      edit(id) {
        if (this.activeName == 0) {
        }
      },
      //保存并更新
      addsubmit() {
        this.$refs.zhanghao.submitForm('1')
        console.log(this.activeName, 'hhy选项')
        this.$emit('getlist')
        // this.dialogVisible = false
        // this.$refs.zhanghao.resetForm()
      },
      //取消
      cancel() {
        this.$refs.zhanghao.resetForm()
        this.dialogVisible = false
      },
      show(e) {
        this.dialogVisible = e
        this.$refs.zhanghao.handlerquytu()
      },
      //保存
      submit() {
        this.$refs.zhanghao.submitForm('2')
        console.log('保存')
        this.$emit('getlist')
        // this.dialogVisible = false
      },
      //弹窗上方关闭
      handleClose(done) {
        done()
        this.$refs.zhanghao.resetForm()
      },
      close() {
        this.dialogVisible = false
      },
    },
  }
</script>

<style>
  .dialong {
    display: flex;
    justify-content: center;
    /* width: 100%; */
  }
</style>
