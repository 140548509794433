var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper haerd" },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "haerdform",
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.formInline },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "depart_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "130px" },
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "选择部门",
                      },
                      model: {
                        value: _vm.formInline.depart_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "depart_id", $$v)
                        },
                        expression: "formInline.depart_id",
                      },
                    },
                    _vm._l(_vm.baseAdminsd, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "role_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "130px" },
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "选择角色",
                      },
                      model: {
                        value: _vm.formInline.role_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "role_id", $$v)
                        },
                        expression: "formInline.role_id",
                      },
                    },
                    _vm._l(_vm.rolelist, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.role_name, value: item.role_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "job_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "130px" },
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "选择职能",
                      },
                      model: {
                        value: _vm.formInline.job_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "job_id", $$v)
                        },
                        expression: "formInline.job_id",
                      },
                    },
                    _vm._l(_vm.position, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "is_wx" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "130px" },
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "绑定微信",
                      },
                      model: {
                        value: _vm.formInline.is_wx,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "is_wx", $$v)
                        },
                        expression: "formInline.is_wx",
                      },
                    },
                    _vm._l(_vm.wechat, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "keyword" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: { placeholder: "请输入姓名，电话" },
                    model: {
                      value: _vm.formInline.keyword,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "keyword", $$v)
                      },
                      expression: "formInline.keyword",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "130px" },
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "状态",
                      },
                      model: {
                        value: _vm.formInline.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "status", $$v)
                        },
                        expression: "formInline.status",
                      },
                    },
                    _vm._l(_vm.status, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("add-employee", {
                    ref: "addedit",
                    on: { getlist: _vm.getlist, refresh: _vm.handlerData },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tableWrap" },
        [
          _c("div", { staticClass: "setFilter" }),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "tableSort",
              attrs: { stripe: "", data: _vm.list },
            },
            [
              _c(
                "el-table-column",
                { attrs: { align: "center", type: "index", width: "50" } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function ($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList",
                              },
                            },
                            _vm._l(_vm.columns, function (item, index) {
                              return _c("el-checkbox", {
                                key: index,
                                attrs: { label: item.label },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference",
                            },
                            [
                              _c("vab-remix-icon", {
                                attrs: { icon: "settings-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: { align: "center", type: "selection", width: "50" },
              }),
              _vm._l(_vm.finallyColumns, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: item.prop,
                    align: item.align,
                    label: item.label,
                    width: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      item.label === "排序"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  on: {
                                    blur: function ($event) {
                                      return _vm.blur_(row)
                                    },
                                  },
                                  model: {
                                    value: row.sort,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        row,
                                        "sort",
                                        _vm._n(
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      )
                                    },
                                    expression: "row.sort",
                                  },
                                }),
                              ]
                            },
                          }
                        : item.label == "角色"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.is_admin == 1
                                  ? _c("span", [_vm._v("管理员")])
                                  : _c("span", [_vm._v(_vm._s(row.user_role))]),
                              ]
                            },
                          }
                        : item.label == "可改价"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content:
                                        row.setPrice === false
                                          ? "点击开启"
                                          : "点击关闭",
                                      enterable: false,
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c("el-switch", {
                                      model: {
                                        value: row.setPrice,
                                        callback: function ($$v) {
                                          _vm.$set(row, "setPrice", $$v)
                                        },
                                        expression: "row.setPrice",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "",
                  align: "center",
                  label: "操作",
                  fixed: "right",
                  "min-width": "200px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.is_close != 1,
                                expression: "row.is_close != 1",
                              },
                            ],
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.editRow($index, row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.is_admin != 1 && row.is_close != 1,
                                expression:
                                  "row.is_admin != 1 && row.is_close != 1",
                              },
                            ],
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handlePermissions($index, row)
                              },
                            },
                          },
                          [_vm._v(" 数据权限 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.is_close == 0 && row.is_admin != 1,
                                expression:
                                  "row.is_close == 0 && row.is_admin != 1",
                              },
                            ],
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.saveRow(1, row)
                              },
                            },
                          },
                          [_vm._v(" 停用 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.is_close == 1,
                                expression: "row.is_close == 1",
                              },
                            ],
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.saveRow(0, row)
                              },
                            },
                          },
                          [_vm._v(" 启用 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-row",
            { staticClass: "row-bg", attrs: { type: "flex", justify: "end" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                  "page-sizes": [10, 20, 30, 40, 50, 100],
                  "page-size": 10,
                  "current-page": _vm.formInline.pageNo,
                  total: _vm.Totalnumber,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.formInline, "pageNo", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.formInline, "pageNo", $event)
                  },
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("edit-row", {
        ref: "editrow",
        on: { getlist: _vm.getlist, refresh: _vm.handlerData },
      }),
      _c("permissions", {
        ref: "permissions",
        on: { refresh: _vm.handlerData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }