<!--
 * @Author: 编辑
 * @Date: 2020-11-11 19:42:56
 * @LastEditTime: 2021-04-25 16:00:17
 * @LastEditors: Please set LastEditors
 * @Description: 编辑
 * @FilePath: \sd-vue-admin\src\views\project\baseOrder\aboutSatff\satffDetail\components\editRow.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="编辑"
      :visible.sync="dialogVisible"
      width="60%"
    >
      <div>
        <el-form
          ref="form"
          :model="form"
          label-width="200px"
          :rules="rules"
          :inline="true"
          size="mini"
          :label-position="labelPosition"
        >
          <el-form-item label="姓名" prop="user_name">
            <el-input
              v-model="form.user_name"
              placeholder="请输入姓名"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input
              v-model="form.mobile"
              placeholder="请输入手机号"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="部门" prop="depart_id">
            <el-select
              v-model="form.depart_id"
              filterable
              placeholder="请选择部门"
              autocomplete="off"
              style="width: 178px"
            >
              <el-option
                v-for="list in baseAdminsd"
                :key="list.id"
                :label="list.text"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职能" prop="user_type">
            <el-select
              v-model="form.user_type"
              multiple
              filterable
              autocomplete="off"
              placeholder="请选择职位"
              style="width: 178px"
            >
              <el-option label="销售" value="1"></el-option>
              <el-option label="送货" value="2"></el-option>
              <el-option label="内部管理" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="办公电话" prop="office_mobile">
            <el-input
              v-model="form.office_mobile"
              placeholder="请输入办公电话"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="销售区域" prop="areas">
            <el-select
              v-model="form.areas"
              multiple
              filterable
              autocomplete="off"
              placeholder="请选择销售区域"
              style="width: 178px"
            >
              <el-option
                v-for="(list, index) in areasd"
                :key="index"
                :label="list.area_name"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-row>
            <el-divider></el-divider>
          </el-row>
          <el-form-item label="开通账号" prop="account_status">
            <el-select
              v-model="form.account_status"
              :disabled="form.is_admin == 1"
              filterable
              autocomplete="off"
              placeholder="请选择账号"
              style="width: 178px"
            >
              <el-option
                v-for="(list, inde) in zhagnhao"
                :key="inde"
                :label="list.text"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-row
            v-if="form.account_status != '0' && form.account_status != ''"
          >
            <el-form-item label="角色" prop="roles">
              <el-select
                v-model="form.roles"
                :disabled="form.is_admin == 1"
                multiple
                filterable
                placeholder="请选择角色"
                style="width: 178px"
              >
                <el-option
                  v-for="(item, listindex) in rolelist"
                  :key="listindex"
                  :label="item.role_name"
                  :value="item.role_id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="登录账号" prop="login_user">
              <el-input
                v-model="form.login_user"
                placeholder="请输入登录账号"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="pwd">
              <el-input
                v-model="form.pwd"
                type="password"
                placeholder="请输入登录密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="允许改销售价" prop="is_edit_price">
              <el-switch
                v-model="form.is_edit_price"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
            <el-form-item label="允许改调拨价" prop="is_edit_price">
              <el-switch
                v-model="form.is_edit_price_allot"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
          </el-row>
          <div class="purple">个人信息</div>
          <el-row>
            <el-divider></el-divider>
          </el-row>
          <el-form-item label="性别" prop="sex">
            <el-select
              v-model="form.sex"
              placeholder="请选择性别"
              filterable
              style="width: 178px"
            >
              <el-option
                v-for="(item, und) in sex"
                :key="und"
                :label="item.text"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职务" prop="user_job">
            <el-input v-model="form.user_job"></el-input>
          </el-form-item>
          <el-form-item label="住址" prop="address">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idcard">
            <el-input v-model="form.idcard"></el-input>
          </el-form-item>
          <el-form-item label="亲友姓名" prop="friends_name">
            <el-input v-model="form.friends_name"></el-input>
          </el-form-item>
          <el-form-item label="亲友电话" prop="friends_mobiel">
            <el-input v-model="form.friends_mobiel"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import md5 from 'md5'
  import { postAction } from '@/api/Employee'
  export default {
    data() {
      var checkPhone = (rule, value, callback) => {
        const phoneReg = /^1[2|3|4|5|6|7|8|9][0-9]{9}$/
        if (!value) {
          return callback(new Error('电话号码不能为空'))
        }
        setTimeout(() => {
          if (!Number.isInteger(+value)) {
            callback(new Error('请输入数字值'))
          } else {
            if (phoneReg.test(value)) {
              callback()
            } else {
              callback(new Error('电话号码格式不正确'))
            }
          }
        }, 100)
      }
      return {
        sex: [
          {
            text: '男',
            id: 1,
          },
          {
            text: '女',
            id: 2,
          },
        ],
        zhagnhao: [
          {
            text: '暂不开通',
            id: 0,
          },
          {
            text: '试用账号',
            id: 1,
          },
          {
            text: '正式账号',
            id: 2,
          },
        ],
        dialogVisible: false,
        areasd: {}, //quyu
        baseAdminsd: '',
        rolelist: '',
        labelPosition: 'right',
        form: {
          user_name: '',
          mobile: '',
          depart_id: '',
          user_type: '',
          areas: '',
          roles: '',
          is_edit_price: 1,
          is_edit_price_allot: 0,
          is_close: '0',
          account_status: '',
          login_user: '',
          pwd: '',
          //个人信息
          user_job: '',
          sex: '',
          address: '',
          idcard: '',
          friends_name: '',
          friends_mobiel: '',
        },
        rules: {
          user_name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
            {
              message: '请输入名称',
              trigger: 'blur',
            },
          ],
          mobile: [{ required: true, validator: checkPhone, trigger: 'blur' }],
          depart_id: [
            { required: true, message: '请选择部门', trigger: 'change' },
          ],
          user_type: [
            { required: true, message: '请选择职能', trigger: 'blur' },
          ],
        },
        url: {
          userxq: '/baseAdmin/user/detail',
          area: '/baseAdmin/dealer-area/index',
          baseAdmin: '/baseAdmin/depart/index',
          role: '/baseAdmin/common/role-list',
          saveuser: '/baseAdmin/user/update',
        },
      }
    },
    watch: {
      // 'form.mobile'(val) {
      //   this.form.login_user = val
      // },
      dialogVisible(val) {
        if (!val) {
          this.form = {
            user_name: '',
            office_mobile: '',
            mobile: '',
            depart_id: '',
            user_type: '',
            areas: '',
            roles: '',
            is_edit_price: 1,
            is_close: '0',
            account_status: '',
            login_user: '',
            pwd: '',
            //个人信息
            user_job: '',
            sex: '',
            address: '',
            idcard: '',
            friends_name: '',
            friends_mobiel: '',
          }
        } else {
          this.handlerquytu()
        }
      },
    },
    mounted() {
      this.handlerbumen()
      this.handlerjuse()
    },
    methods: {
      //区域列表
      handlerquytu() {
        var data = {
          pageNo: 1,
          pageSize: 1000,
        }
        postAction(this.url.area, data)
          .then((res) => {
            console.log(res, '区域')
            this.areasd = res.data
          })
          .catch((err) => {})
      },
      //部门列表
      handlerbumen() {
        postAction(this.url.baseAdmin, { is_close: 0 })
          .then((res) => {
            console.log(res, 'bumen')
            this.baseAdminsd = res.data.list
          })
          .catch((err) => {})
      },
      //角色
      handlerjuse() {
        postAction(this.url.role, {})
          .then((res) => {
            console.log(res, 'chenggong')
            this.rolelist = res.data
          })
          .catch((err) => {})
      },
      handlerData(row) {
        postAction(this.url.userxq, { id: row.user_id })
          .then((res) => {
            console.log(res, '用户数据')
            this.form = res.data
            // this.formpwd = ''
            this.form.is_close = String(this.form.is_close)
            // this.form.pwd = ''
            this.$set(this.form, '', paw)
            console.log(this.form, '组装后的数据')
          })
          .catch((err) => {})
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            done()
          })
          .catch((_) => {})
      },
      close() {
        this.$refs['form'].resetFields()
        this.dialogVisible = false
      },
      save() {
        console.log(this.form.pwd, 'md5加密')
        // let a = val || ""
        if (this.form.pwd == undefined || this.form.pwd == '') {
          let pass = ''
          console.log(this.form.pwd, '密码为空')
          pass = this.form.pwd
          this.form.pwd = pass
        } else {
          let password = ''
          console.log(this.form.pwd, '密码不为空')
          password = md5(this.form.pwd)
          this.form.pwd = password
        }

        if (this.form.areas.length > 0) {
          this.form.areas = JSON.stringify(this.form.areas)
        }
        if (this.form.roles.length > 0) {
          this.form.roles = JSON.stringify(this.form.roles)
        }
        if (this.form.user_type) {
          this.form.user_type = JSON.stringify(this.form.user_type)
        }
        postAction(this.url.saveuser, this.form)
          .then((res) => {
            console.log(res, '成功')
            if (res.code == 200) {
              this.$message({
                message: '修改成功',
                type: 'success',
              })
              this.$emit('refresh')

              this.dialogVisible = false
            }
          })
          .catch((err) => {
            console.log(err, '失败')
          })
      },
    },
  }
</script>
<style></style>
